<template>
  <div class="capacity">
    <Swiper></Swiper>
    <!-- <SubNav></SubNav>
    <box></box>
    <Sixbox></Sixbox>
    <four-boxa></four-boxa> -->
    <!-- 平台 -->
    <div class="capacityMain">
     
      <div class="engineer">
        <router-link to="/engineer">赋能工程详情介绍</router-link>
      </div>
       <div class="left">
        <!-- <p class="l1">服能机构分布图</p> -->
        <router-link to="/ambassador">点击查看赋能大使介绍</router-link>

      </div>
      <div class="platform">
        <!-- <div ref="myChart" :style="{ width: '1200px', height: '900px' }"></div> -->
        <img class="mapImg" :src="mapImg" alt="">
      </div>
    </div>
   <!-- <div class="china">
     <img class="xinjiang" :src="xinjiang" alt="" srcset=""    ondragstart="return false;"
        oncontextmenu="return false;">
     <img class="xizang" :src="xizang" alt="" srcset=""    ondragstart="return false;"
        oncontextmenu="return false;">
   </div> -->
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Box from "./modules/box.vue";
import Sixbox from "./modules/SixBoxa.vue";
import FourBoxa from "./modules/FourBoxa.vue";
import Message from "@/components/message/Message.vue";
import { ref } from "vue";
import * as echarts from "echarts";
import "../../../node_modules/echarts/map/china.js"; // 引入中国地图数据
export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Box,
    Sixbox,
    FourBoxa,
  },
  data() {
    return {
      mapImg: require("@/assets/1new/赋能工程/赋能工程地图.jpg"),
    };
  },
  methods: {},
  setup() {
    const myChart = ref<HTMLDivElement>(); //也可以用const myChart = ref<any>();

    // console.log(myChart);
    const myCharts = ref<any>();
    setTimeout(() => {
      // 绘制图表
      myCharts.value = echarts.init(myChart.value!);
      myCharts.value.setOption({
        backgroundColor: "#f0f0f0",
        tooltip: {
          trigger: "item",
        }, // 鼠标移到图里面的浮动提示框
        visualMap: {
          //左侧小柱子的配置
          //  min: 0, // 最小值
          //  max:15000, //最大值
          left: "left", // 定位左边
          top: "bottom", // 定位底部
          splitList: [
            { start: 500, end: 600 },
            { start: 400, end: 500 },
            { start: 300, end: 400 },
            { start: 200, end: 300 },
            { start: 100, end: 200 },
            { start: 0, end: 100, label: "1" },
          ],
          color: [
            "#5475f5",
            "#9feaa5",
            "#85daef",
            "#74e2ca",
            "#e6ac53",
            "#02787a",
          ],
          calculable: false, // 显示与否
          show: false,
        },
        title: {
          text: "赋能机构分布图",
          subtext: "点击查看赋能大使介绍",
          // sublink: 'http://www.baidu.com',
          sublink: "/#/ambassador",
          // target: "_self",
          left: "left",
          textStyle: {
            color: "#87b335",
          },
        },
//
        geo: {
          // 这个是重点配置区
          map: "china", // 表示中国地图
          roam: false,
          label: {
            normal: {
              show: true, // 是否显示对应地名
              textStyle: {
                color: "black", // 地名颜色
                fontSize: "14",
              },
            },
          },
          itemStyle: {
            normal: {
              borderColor: "#fff", //边界颜色
              areaColor: "#00a5a8", //区域颜色绿色
            },
            emphasis: {
              //高亮时
              areaColor: "#87b335",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: "rgba(255, 110, 110, 0.5)",
            },
          },
        },
        series: [
          {
            type: "scatter",
            coordinateSystem: "geo", // 对应上方配置
            mapType: "china",
            label: {
            },
             normal: {
                show: true, //显示省份标签
                textStyle: { color: "#c71585" }, //省份标签字体颜色
              },
              emphasis: {
                //对应的鼠标悬浮效果
                show: true,
                textStyle: { color: "#800080" },
              },
          },
          {
            name: "大使数量", // 浮动框的标题
            type: "map",
            geoIndex: 0,
            data: [
              { name: "广东", value: 11 },
              { name: "黑龙江", value: 13 },
              { name: "山东", value: 22 },
              { name: "内蒙古", value: 1},
              { name: "山西", value: 2 },
            ],
            // }
          },
        ],
      });
    }, 10);
    //     myCharts.value.on('click', function (params) {
    //     alert(params.name);
    // });
    return {
      myChart,
    };
  },
});
</script>
<style lang="scss" scoped>
// .news {
//   width: 1200px;
//   // min-height: 200px;
//   background-color:skyblue;
//   height: 100%;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   position: relative;
//   display: block;
// }
.china{
  width: 1200px;
  height: 1000px;
  background-color: #fff;
  position: relative;
  .xinjiang{
    position: absolute;
    left: 246px;
    top: 359px;
  }
  .xizang{
    position: absolute;
     left: 228px;
    top: 599px;
  }

}
.platform {
  width: 1200px;
  min-height: 300px;
  background-color: #fff;
  position: relative;
}
.mapImg{
  width: 1200px;
  height: 840px;
  margin-top: 150px;
}
.capacity {
  position: relative;
}
.capacityMain {
  position: relative;
  background-color: #fff;
  .left{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    .l1{
      font-size: 18px;
      color: #000;
      margin-bottom: 10px;
    }
    .l2{
      font-size: 12px;
      color: #05c605;
    }
  }
}
.engineer {
  font-size: 24px;
  width: 225px;
  height: 54px;
  padding: 14px;
  background-color: #ff0000;
  box-sizing: border-box;
  color: #fff;
  position: absolute;
  z-index: 2;
  left: 340px;
  cursor: pointer;
  border-radius: 27px;
}
.left{
    font-size: 24px;
  width: 285px;
  height: 54px;
  padding: 14px;
  background-color: #ff0000;
  box-sizing: border-box;
  color: #fff;
  position: absolute;
  z-index: 2;
  right: 0px;
  cursor: pointer;
   border-radius: 27px;
   .l2{
     color: #fff;
   }

}
.message {
  width: 1200px;
  // background-color: skyblue;
  display: block;
  height: 100%;
  margin-bottom: 20px;
  margin-top: 56px;
}
</style>
